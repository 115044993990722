// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Anton+SC&display=swap');
.anton-sc-regular {
    font-family: "Anton SC", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  
  .anton-sc-bold {
    font-family: "Anton SC", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .anton-sc-regular-italic {
    font-family: "Anton SC", sans-serif;
    font-weight: 400;
    font-style: italic;
  }

  .toast-perso {
      margin-top: 80px !important;
  }